// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-agency-js": () => import("./../../../src/templates/agency.js" /* webpackChunkName: "component---src-templates-agency-js" */),
  "component---src-templates-agency-overview-js": () => import("./../../../src/templates/agency-overview.js" /* webpackChunkName: "component---src-templates-agency-overview-js" */),
  "component---src-templates-blog-overview-js": () => import("./../../../src/templates/blog-overview.js" /* webpackChunkName: "component---src-templates-blog-overview-js" */),
  "component---src-templates-estimation-tool-js": () => import("./../../../src/templates/estimation-tool.js" /* webpackChunkName: "component---src-templates-estimation-tool-js" */),
  "component---src-templates-favourites-js": () => import("./../../../src/templates/favourites.js" /* webpackChunkName: "component---src-templates-favourites-js" */),
  "component---src-templates-landing-js": () => import("./../../../src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-templates-property-details-js": () => import("./../../../src/templates/property-details.js" /* webpackChunkName: "component---src-templates-property-details-js" */),
  "component---src-templates-property-overview-js": () => import("./../../../src/templates/property-overview.js" /* webpackChunkName: "component---src-templates-property-overview-js" */)
}

